import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { GiftStep1, GiftStep2, GiftStep3, GiftCongratulation } from '@assets/image/png';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Style } from '@features/e-cart/style';

const stepHint = [
  {
    img: GiftStep1 as string,
    hint: 'e-gift.step1',
  },
  {
    img: GiftStep2 as string,
    hint: 'e-gift.step2',
  },
  {
    img: GiftStep3 as string,
    hint: 'e-gift.step3',
  },
];

const EGiftTemplateCompletePage: FC = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Style.Wrap>
        <Style.Container style={{ background: 'white', borderColor: 'white' }}>
          <h2 className="text-center my-3 mx-auto">
            <FormattedMessage id="e-gift.hint5" />
          </h2>
          <img
            className="d-block"
            src={GiftCongratulation as string}
            alt="GiftCongratulation"
            style={{ height: '250px', objectFit: 'cover' }}
          />
          <p className="text-center">
            <FormattedMessage id="e-gift.hint6" />
          </p>
          <p className="text-center">
            <a href="https://tasty-mall.com/message">https://tasty-mall.com/message</a>
          </p>
          <form>
            <div className="form-group">
              <Button
                className="my-3"
                width="250px"
                onClick={() => {
                  if (typeof document !== 'undefined') {
                    const textField = document.createElement('textarea');
                    textField.innerText = 'https://tasty-mall.com/message';
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand('copy');
                    textField.remove();
                  }
                }}
              >
                <FormattedMessage id="url.copy" />
              </Button>
            </div>
          </form>
        </Style.Container>
        <Style.Container>
          {stepHint.map((step, index) => (
            <React.Fragment key={`step-title-${index}`}>
              <h1 className="x-large text-center mt-5">0{index + 1}.</h1>
              {intl
                .formatMessage({ id: step.hint })
                .split(/\r?\n/)
                .map((s) => (
                  <p className="mx-auto mb-1 text-center" style={{ maxWidth: '550px' }} key={s}>
                    {s}
                  </p>
                ))}
              <img className="d-block m-auto m-3" src={step.img} alt="Step ICON" />
            </React.Fragment>
          ))}
        </Style.Container>
        <Style.Container style={{ background: 'white', borderColor: 'white' }}>
          <form>
            <div className="form-group">
              <Button
                className="my-3 secondary"
                width="200px"
                onClick={() => {
                  navigate('/mypage/purchase-history');
                }}
              >
                <FormattedMessage id="go.to.purchase" />
              </Button>
              <Button
                className="my-3 secondary"
                width="200px"
                onClick={() => {
                  navigate('/');
                }}
              >
                <FormattedMessage id="go.to.top" />
              </Button>
            </div>
          </form>
        </Style.Container>
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftTemplateCompletePage);
